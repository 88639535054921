import Swal from 'sweetalert2'

const ruleActions = ["jump-to","killer","finish-test","quality-killer","cond-show","cond-show-option","quality-killer-nosanction"]

export const formatNavigationRules = function(navigationRules: any, isActivity: boolean){
  let rulesFormated = []

  for(let r of navigationRules){
    let rule = "if "
    for(let c of r.conditions){
      if (c.optionId == null || c.optionId == undefined || c.optionId == "" && c.optionId != 0) rule += "task" + c.taskNum + "-question"+c.qId + c.equality + c.valId
      else rule += "task" + c.taskNum +"-question"+c.qId + "-option" + c.optionId + c.equality + c.valId
      if (c.operator != null) rule += c.operator
    }
    rule += " then " + r.action.type
    if (r.action.type == "jump-to" || r.action.type == "cond-show") rule += " task" + r.action.taskNum +"-question" + r.action.qId
    if (r.action.type == "cond-show-option") rule += " task" + r.action.taskNum + "-question" + r.action.qId + "-option" + r.action.valId
    if (isActivity) rule = inspectNavigationRule(rule);
    rulesFormated.push(rule)
  }

  return rulesFormated
}


export const navigationRulesHasErrors = function(navigationRules: any, withErrorDetail: any){
  let rules: any = navigationRules
  let errors: any = []
  let errorDetail: any = []
  for(let i in rules){
    let ruleErrorDetail = {
      qId: rules[i].conditions[0].qId,
      taskNum: rules[i].conditions[0].taskNum,
      error: false
    }
    if((rules[i].action.type == "jump-to" || rules[i].action.type == "cond-show" || rules[i].action.type == "cond-show-option") && rules[i].action.qId == null){
      errors.push("rulequestion-"+i)
      ruleErrorDetail.error = true
    }
    if(rules[i].action.type == "cond-show-option" && rules[i].action.valId == null){
      errors.push("ruleval-"+i)
      ruleErrorDetail.error = true
    } 
    for(let i2 in rules[i].conditions){
      if(rules[i].conditions[i2].valId == null || (rules[i].conditions[i2].equality == "#" && rules[i].conditions[i2].valId == 0)){
        errors.push("rulecondition-"+i+"-"+i2)
        ruleErrorDetail.error = true
      }
    }
    if(ruleErrorDetail.error) errorDetail.push(ruleErrorDetail)
  }
  if(withErrorDetail) return {errorDetail: errorDetail, error: errors.length > 0}
  if(errors.length > 0) return true
}

export const getQuestionMaxId = function(questions: any){
  let id = 0
  for(let q of questions) {
    if(parseInt(q.id) > id) id = parseInt(q.id)
  }
  return id + 1
}

export const getQuestionValueMaxId = function(values: any){
  let id = 0
  for(let q of values) {
    if(parseInt(q.id) > id) id = parseInt(q.id)
  }
  return id + 1
}

export const isQuestionWithValues = (type: any) => {
  return type == 'SELECTION' || type == 'MULTISELECTION' || type == 'SORTING' || type == 'DIVIDE_POINTS' || type == 'MATRIX' || type == 'IMAGE_CHOICE' || type == 'MULTIPLEIMAGE_CHOICE'
}

export const isQuestionText = (type: any) => {
  return type == 'TEXT' || type == 'MULTITEXT' || type == 'TEXTAREA'
}

export const addRule = (navigationRules: any, question: any, taskNum?: any) => {
  navigationRules.push({
    conditions:[
      {equality:"=",operator:null,taskNum:taskNum ? taskNum : 1,qId:question.id,valId:0}
    ],
    action:{
      type:"jump-to",taskNum: taskNum ? taskNum : 1,qId:null
    }
  })
}

export const removeRule = (navigationRules: any, index: any) => {
  navigationRules.splice(index,1)
}

export const generateNavigationRules = (navigationRules: any, data: any) => {
  for (let i = 0;i<data.length;i++){
    let condition = data[i].split("then")[0].replace("if","")
    let action = data[i].split("then")[1]
    let conditionCount = (condition.match(/task/g) || []).length
    navigationRules[i] = {}
    navigationRules[i].conditions = []
    navigationRules[i].action = ruleActionToJson(action)
    if(conditionCount > 1){
      for (let x=0;x<conditionCount;x++) {
        if(x == conditionCount - 1){
          let values = condition.match(/\d+/g)
          let equality = getRuleEquality(condition)
          if (values.length > 3) navigationRules[i].conditions.push({operator:null,taskNum:values[0],equality: equality, qId:values[1],optionId:values[2],valId:values[3]})
          else navigationRules[i].conditions.push({operator:null,taskNum:values[0],equality: equality, qId:values[1],valId:values[2]})
        }
        else{
          let andOperator= condition.indexOf("&")
          let orOperator= condition.indexOf("|")
          let operator = ""
          let operatorPos = ""
          if (andOperator == -1){
            operator = "|"
            operatorPos = orOperator
          }
          else if (orOperator == -1){
            operator = "&"
            operatorPos = andOperator
          }
          else if (andOperator > orOperator){
            operator = "|"
            operatorPos = orOperator
          }
          else{
            operator = "&"
            operatorPos = andOperator
          }
          let values = condition.substr(0,operatorPos).match(/\d+/g)
          let equality = getRuleEquality(condition.substr(0,operatorPos))
          condition = condition.substr(operatorPos).replace(operator,"")
          if (values.length > 3) navigationRules[i].conditions.push({operator:operator,taskNum:values[0],equality: equality, qId:values[1],optionId:values[2],valId:values[3]})
          else navigationRules[i].conditions.push({operator:operator,taskNum:values[0],equality: equality, qId:values[1],valId:values[2]})
        }
      }
    }
    else{
      let values = condition.match(/\d+/g)
      let equality = getRuleEquality(condition)
      if (values.length>3)navigationRules[i].conditions.push({operator:null,taskNum:values[0],equality: equality, qId:values[1],optionId:values[2],valId:values[3]})
      else navigationRules[i].conditions.push({operator:null,taskNum:values[0],equality: equality, qId:values[1],valId:values[2]})
    }
  }
}

const ruleActionToJson = (action: any) => {
    let actionReturn = {type:"",taskNum:"",qId:"", valId: null}
    for(let i=0; i<ruleActions.length;i++){
      if(action.indexOf(ruleActions[i]) != -1){
        actionReturn.type = ruleActions[i]
        if(actionReturn.type != ruleActions[1] && actionReturn.type != ruleActions[2] && actionReturn.type != ruleActions[3] && actionReturn.type != ruleActions[6]){
          let matchNumbers = action.match(/\d+/g)
          actionReturn.taskNum = matchNumbers[0]
          actionReturn.qId = matchNumbers[1]
          if (ruleActions[i] == "cond-show-option") actionReturn.valId = matchNumbers[2]
        }
      }
    }
    return actionReturn
}

const getRuleEquality = (condition: any) => {
  let equality = ""
  if (condition.indexOf("==") != -1) equality = "=="
  else if (condition.indexOf("!=") != -1) equality = "!="
  else if (condition.indexOf("#") != -1) equality = "#"
  else equality = "="
  return equality
}

export const createQuestion = (questions: any, type: any) => {
    let question: any = {
      type: type.type,
      text:'',
    }
    if(question.type == 'MULTISELECTION') createMultiSelectionQuestion(question)
    if(question.type == 'SELECTION')      addValue(question, false)
    if(question.type == 'RANKING') createRankingQuestion(question, type)
    if(question.type == 'RATING_FACES') createRatingFacesQuestion(question, type)
    if(question.type == 'MULTITEXT') createMultiTextQuestion(question)
    if(question.type == 'DIVIDE_POINTS') createDividePointsQuestion(question)
    if(question.type == 'MATRIX') createMatrixQuestion(question, type)
    if(question.type == 'MULTIPLEIMAGE_CHOICE' || question.type == 'IMAGE_CHOICE') addValue(question, false)
    addQuestion(question, questions)
}

export const duplicateQuestion = (question: any, questions: any) => {
  addQuestion(question, questions)
}

const addQuestion = (question: any, questions: any) => {
  let q = JSON.parse(JSON.stringify(question))
  q.id = getQuestionMaxId(questions),
  q.order = questions.length + 1,
  questions.push(q)
}

const createMultiSelectionQuestion = (question: any) =>{
  question.multiselectionMax = 1
  question.multiselectionMin = 1
  addValue(question, false)
}

const createRankingQuestion = (question: any, type: any) => {
  question.rankingMin = 0
  question.rankingMax = 0
  question.rankingLeftLabel = null
  question.rankingRightLabel = null
  question.rankingType = type.name == 'RANKING-STARS' ? 'STARS' : 'NUMBERS'
}

const createRatingFacesQuestion = (question: any, type: any) => {
  question.rankingMin = 1
  question.rankingMax = 5
  question.rankingLeftLabel = null
  question.rankingRightLabel = null
  question.rankingType = type.name == 'RANKING-STARS' ? 'STARS' : 'NUMBERS'
}

const createMultiTextQuestion = (question: any) => {
  question.placeholders = ""
  question.textDescriptions = ""
  question.textNum = 1
}

const createDividePointsQuestion = (question: any) => {
  question.pointsNum = null
  addValue(question, false)
}

const createMatrixQuestion = (question: any, type: any) => {
  question.rankingMin = 0
  question.rankingMax = 0
  if(type.name == 'matrix-options'){
    question.matrixRadioButton = true
    question.matrixVisualization = 'RADIO_BUTTONS'
  }
  addValue(question, false)
  addOptionMatrix(question)
}

export const addValue = (question: any, addTxt: Boolean) => {
  const isImageChoiceQuestion = question.type == 'MULTIPLEIMAGE_CHOICE' || question.type == 'IMAGE_CHOICE'
  if(!question.values) question.values = []
  const value = {
    id:  getQuestionValueMaxId(question.values),
    value:'',
    image: isImageChoiceQuestion ? '' : undefined,
    addtxt: addTxt
  }
 question.values.push(value)
}

export const addOptionMatrix = (question: any) => {
  if(!question.radioOptionTexts) question.radioOptionTexts = []
  question.radioOptionTexts.push('')
}

export const reorderQuestions = (questions: any) => {
  questions = questions.map(function(q, index){
    q.order = index + 1
    return q
  })
}

export const canRemoveQuestion = async (questionIndex: any, questions: any, navigationRules: any, translator: any) => {
  let q = questions[questionIndex]
  let rules = getRulesIndexToRemove(q, navigationRules)
  if(rules.length > 0){
    await Swal.fire({
      title: translator('msg_confirmation_delete_question'),
      text: translator('msg_confirmation_delete_question_text'),
      icon: 'warning',
      cancelButtonText: translator('action_cancel'),
      confirmButtonText: translator('msg_agree'),
      showCancelButton: true,
      customClass:{
        popup:'swal-customWarning'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        removeQuestion(questionIndex, questions)
        for(let index of rules){
          navigationRules.splice(index,1)
        }
      }
    })
  }
  else removeQuestion(questionIndex, questions)
}

const getRulesIndexToRemove = (q: any, navigationRules: any) => {
  let rulesIndex = []
  let index = 0
  for(let r of navigationRules){
    if(r.action.qId == q.id) rulesIndex.unshift(index)
    for(let c of r.conditions){
      if(c.qId == q.id && !rulesIndex.includes(index)) rulesIndex.unshift(index)
    }
    index++
  }
  return rulesIndex
}

const removeQuestion = (index: any, questions: any) =>{
  questions.splice(index, 1)
  reorderQuestions(questions)
}

export const removeQuestionValueOption = (questions: any, index: any, optionIndex: any) => {
  questions[index].radioOptionTexts.splice(optionIndex, 1)
}

export const removeQuestionValue = (questions: any, index: any, valueIndex: any) => {
  questions[index].values.splice(valueIndex, 1)
}

export const addTask = (tasks: any, taskType:any = false) => {
  const task = {
    taskNum: tasks.length + 1,
    name: '',
    description: '',
    uxQuantiTask: taskType === 'watuxquanti', // Asignar true si el tipo es watuxquanti
    uxTask: taskType === 'watux', // Asignar true si el tipo es watux
    clickTestTask: taskType === 'clicktest',
    questions: [],
    uxQuantiData: {
      desktopStartUrl: '',
      desktopSuccessUrls: [{ name: '', url: '' }],
      mobileStartUrl: '',
      mobileSuccessUrls: [{ name: '', url: '' }],
      maxTime: 1,
      minTime: 1,
    },
    uxData: {
      initialUrl: '',
      recordScreen: false,
      recordMicrophone: false,
      recordWebcam: false,
      checkpoints: [],
      onlyRecord: false,
    },
    clickTestData: {
      imageUrl: '',
      timeoutSeconds: 0,
      message: '',
      successZoneTL_X: 0,
      successZoneTL_Y: 0,
      successZoneBR_X: 0,
      successZoneBR_Y: 0,
    },
  }
  tasks.push(task)
}

export const removeTask = (tasks: any, index) => {
  tasks.splice(index, 1)
  reorderTasks(tasks)
}

export const duplicateTask = (tasks: any, task: any) => {
  let newTask = JSON.parse(JSON.stringify(task))
  newTask.taskNum = tasks.length + 1
  tasks.push(newTask)
}

export const reorderTasks = (tasks: any) => {
  tasks = tasks.map(function(t, index){
    t.taskNum = index + 1
    return t
  })
}

export const inspectNavigationRule = function(rule: any){
  const regex = /task(?!1)(\d*)-/g;
  return rule.replace(regex, 'task1-');
}
